<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card class="rounded-0 elevation-0">
        <v-card-text>
          <v-row class="ma-0 bg-blk">
            <v-col cols="6" sm="6" class="pb-0">
              <v-select
                :items="yearsList"
                v-model="filter.year"
                solo
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="6" sm="6" class="pb-0">
              <v-select
                :items="monthList"
                v-model="filter.month"
                solo
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12">
              <v-select
                :items="entries"
                v-model="filter.search"
                solo
                dense
                hide-details
                item-text="name"
                item-value="id"
                no-data-text="データがありません"
                label="対象月の現場全て"
              ></v-select>
              <!-- <v-autocomplete
                v-model="filter.search"
                :items="entries"
                :search-input.sync="search"
                hide-selected
                item-text="name"
                item-value="id"
                label="顧客・現場名"
                solo
                dense
                hide-details
                cache-items
                clearable
                no-data-text="データがありません"
              >
              </v-autocomplete> -->
            </v-col>
            <v-progress-linear
              indeterminate
              rounded
              v-if="isLoading"
              height="3"
            ></v-progress-linear>
          </v-row>

          <v-row class="ma-0 mt-4" v-if="operationalHour">
            <v-col cols="12" class="operational-hours-box">
              <v-row>
                <v-col cols="4" class="heading">
                  総労働時間
                </v-col>
                <v-col cols="8" class="value black--text">
                  <span class="hours">{{
                    operationalHour.operational_hours
                  }}</span>
                  時間
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="heading">今月の目標</v-col>
                <v-col
                  cols="8"
                  class="value black--text"
                  style="white-space: pre-wrap"
                  >{{ operationalHour.comments }}</v-col
                >
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import { debounce } from "debounce";
import axios from "@/services/api";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      filter: {
        year: null,
        month: null,
        search: null,
      },
      search: null,
      entries: [],
      isLoading: false,
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    ...mapGetters(["user", "operationalHour"]),
    yearsList() {
      let begin = dayjs().subtract(1, "year");
      let end = begin.add(2, "year");
      let years = [];
      while (begin.isSameOrBefore(end)) {
        let val = begin.format("YYYY年");
        years.push({
          value: parseInt(val),
          text: val,
        });
        begin = begin.add(1, "year");
      }
      return years;
    },
    monthList() {
      let month = [];
      for (let i = 1; i <= 12; i++) {
        month.push({ value: i, text: `${i}月` });
      }
      return month;
    },
    currentYear() {
      let today = dayjs();
      if (today.date() >= 21) {
        return parseInt(today.add(1, "month").format("YYYY"));
      }
      return parseInt(today.format("YYYY"));
    },
    currentMonth() {
      if (this.$route.query.date) {
        return parseInt(dayjs(this.$route.query.date).format("M"));
      }
      let today = dayjs();
      if (today.date() >= 21) {
        return parseInt(today.add(1, "month").format("M"));
      }
      return parseInt(today.format("M"));
    },
  },

  watch: {
    filter: {
      handler: debounce(function() {
        this.getWorkTimeSites();
        this.getDataFromApi();
      }, 1000),
      deep: true,
    },
  },

  created() {
    if (!this.$route.query.pending) {
      this.filter = {
        year: this.currentYear,
        month: this.currentMonth,
        search: null,
      };
    }
  },

  methods: {
    getWorkTimeSites() {
      if (this.isLoading) return;

      this.isLoading = true;
      let params = {};
      let date = dayjs()
        .year(this.filter.year)
        .month(this.filter.month - 1)
        .format("YYYY-MM");
      params.date = date;
      // params.search = val;
      console.log("hello");

      axios
        .get("/user/worktimes/get-sites", {
          params,
        })
        .then((res) => {
          this.entries = res.data.data.sites;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    async getDataFromApi() {
      let params = {};
      this.isLoading = true;
      if (!this.filter.month && !this.filter.year) {
        if (this.$route.query.pending)
          params.pending = this.$route.query.pending;
      } else {
        let date = dayjs()
          .year(this.filter.year)
          .month(this.filter.month - 1)
          .format("YYYY-MM");
        params.date = date;

        if (this.filter.search) params.search = this.filter.search;
      }

      await this.$store.dispatch(
        "WORKTIME_OPERATIONAL_HOURS_GET",
        `${params.date}-01`
      );

      this.$store
        .dispatch("WORKTIMES", {
          params: params,
        })
        .finally(() => {
          this.isLoading = false;
        });
      this.$emit("filter", this.filter);
    },
  },
};
</script>

<style scoped lang="scss">
.bg-blk {
  background: #f9f9f9;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
}
.bg-blk >>> .v-input__slot {
  box-shadow: none !important;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.operational-hours-box {
  background: #fffaed;
  border: 1px solid #b5b5b5;
  border-radius: 5px;

  .heading {
    color: #757900;
  }

  .hours {
    font-size: 20px;
  }
}
</style>