<template>
  <v-row>
    <v-col cols="12" sm="12" v-if="worktimes">
      <v-card
        v-for="worktime in sortedWorktimes"
        :key="worktime.id"
        outlined
        color="#F8F9FF"
        class="mb-3 listing-blk"
        @click="editWorkTime(worktime.id)"
      >
        <v-list-item two-line class="contents">
          <v-list-item-content>
            <div class="mb-5 d-flex flex-row justify-space-between">
              <div class="d-flex flex-row">
                <strong class="mr-2 deep-purple--text text--darken-3">{{
                  formatDate(worktime.task.date)
                }}</strong>
                <strong class="deep-purple--text text--darken-3">{{
                  worktime.task.site.name
                }}</strong>
              </div>
              <small class="grey--text text--darken-1">{{
                getClockType(worktime.type_id)
              }}</small>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <div class="d-flex flex-row">
                <span class="mr-2 grey--text text--darken-1">イン</span>
                <strong class="mr-2">{{ worktime.clock_in }}</strong>
                <span class="mr-2 grey--text text--darken-1">アウト</span>
                <strong>{{ worktime.clock_out }}</strong>
              </div>
              <div class="d-flex flex-row">
                <span class="mr-2 grey--text text--darken-1">休憩</span>
                <strong class="mr-2"
                  >昼 {{ formatRestTime(worktime.rest_hours_day) }}</strong
                >
                <strong
                  >夜 {{ formatRestTime(worktime.rest_hours_night) }}</strong
                >
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["worktimes"]),
    sortedWorktimes() {
      return this.worktimes.filter((a) => a).sort((a,b) => {
        let dateA = dayjs(a.task.date)
        let dateB = dayjs(b.task.date)
        return dateA.isBefore(dateB) ? 1 : -1
      })
    }
  },
  methods: {
    getClockType(type_id) {
      let type = "";
      switch (type_id) {
        case 0:
          type = "現場勤務（リ）";
          break;
        case 1:
          type = "現場勤務（個）";
          break;
        case 2:
          type = "運転・調整";
          break;
        case 3:
          type = "参加";
          break;
        case 4:
          type = "タスク";
          break;
        case 5:
          type = "その他";
          break;

        default:
          break;
      }
      return type;
    },
    formatDate(date) {
      return dayjs(date).format("M/D");
    },
    formatRestTime(time) {
      let newTime = time.split(":");
      let hour = newTime[0];
      let minute = newTime[1];
      if (hour == "0" && minute == "0") return "-";
      else if (hour == "0") return minute + "分";
      else if (minute == "0") return hour + "時間";
      else return hour + "時間" + minute + "分";
    },
    editWorkTime(w_id) {
      this.$router.push({ name: "worktime-edit", params: { id: w_id } });
    },
  },
};
</script>

<style scoped>
.listing-blk {
  border: 1px solid #e0e0e0 !important;
}
.contents * {
  font-size: 0.813rem;
}
</style>
