<template>
  <WorktimeList />
</template>

<script>
import WorktimeList from "@/components/worktime/WorktimeList";
export default {
  name: "Worktime",
  components: { WorktimeList }
};
</script>

<style scoped></style>
