<template>
  <v-row no-gutters>
    <v-col cols="12" sm="12">
      <v-card class="rounded-0 elevation-0">
        <v-card-text v-if="show" class="text-center">
          <span class="information" v-if="filter">
            {{ dateRangeText }}
          </span>
        </v-card-text>
        <v-simple-table v-if="show" dense class="mb-6 px-8 custom-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th class="text-center">昼</th>
                <th class="text-center">夜</th>
                <th class="text-center">計</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in worktimeReports" :key="item.name">
                <td class="text-right">{{ item.label }}</td>
                <td>{{ getTotalWorktime(item.daytime) || "-" }}</td>
                <td>{{ getTotalWorktime(item.nighttime) || "-" }}</td>
                <td>{{ getTotalWorktime(item.total) || "-" }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-actions v-if="show" class="px-9 addition_link">
          <v-btn block outlined color="#333333" @click="goToWorktimeCreate()"
            >勤怠追加</v-btn
          >
        </v-card-actions>
        <v-card-actions class="px-9">
          <v-btn block outlined color="#333333">勤怠詳細</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
import { cloneDeep } from "lodash";
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false
    },
    filter: {
      type: Object,
      default: null,
      required: false
    }
  },

  data() {
    return {
      reportsData: [
        {
          name: "total",
          label: "合計",
          daytime: null,
          nighttime: null,
          total: null
        },
        {
          name: "on_site_leader",
          label: "現場（リ）",
          daytime: null,
          nighttime: null,
          total: null
        },
        {
          name: "on_site_other",
          label: "現場（他）",
          daytime: null,
          nighttime: null,
          total: null
        },
        {
          name: "on_site_individual",
          label: "現場（個）",
          daytime: null,
          nighttime: null,
          total: null
        },
        {
          name: "drive",
          label: "運転・調整",
          daytime: null,
          nighttime: null,
          total: null
        },
        // {
        //   name: "participation",
        //   label: "参加",
        //   daytime: null,
        //   nighttime: null,
        //   total: null
        // },
        // {
        //   name: "task",
        //   label: "タスク",
        //   daytime: null,
        //   nighttime: null,
        //   total: null
        // },
        {
          name: "others",
          label: "その他",
          daytime: null,
          nighttime: null,
          total: null
        }
      ],
      reports: null,
      show: true
    };
  },

  computed: {
    ...mapGetters(["worktimes"]),
    dateRangeText() {
      let today = dayjs()
        .year(this.filter.year)
        .month(this.filter.month)
        .subtract(1, "month");
      let begin = null;
      let end = null;
      if (today.date() < 21) {
        begin = today.subtract(1, "month").date(21);
        end = today.date(20);
      } else {
        begin = today.date(21);
        end = today.add(1, "month").date(20);
      }
      begin = begin.format("YYYY年M月D日");
      end = end.format("YYYY年M月D日");
      return `${begin}～${end}の勤怠サマリー`;
    },
    worktimeReports() {
      let reports = cloneDeep(this.reportsData);

      reports.forEach(report => {
        report.daytime = dayjs.duration({ hours: 0, minutes: 0 });
        report.nighttime = dayjs.duration({ hours: 0, minutes: 0 });
        report.total = dayjs.duration({ hours: 0, minutes: 0 });
      });

      this.worktimes.forEach(worktime => {
        let report;

        if (worktime.type === "on_site_leader") {
          if (worktime.is_leader_time) {
            report = reports[1];
          } else {
            report = reports[2];
          }
        } else {
          report = reports.find(report => report.name === worktime.type);
        }

        report.daytime = this.addTime(report.daytime, worktime.work_hours_day);
        report.nighttime = this.addTime(
          report.nighttime,
          worktime.work_hours_night
        );
        report.total = report.daytime.add(report.nighttime);
      });

      reports.forEach((report, key) => {
        if (key > 0) {
          reports[0].daytime = reports[0].daytime.add(report.daytime);
          reports[0].nighttime = reports[0].nighttime.add(report.nighttime);

          reports[0].total = reports[0].total.add(report.total);
        }
      });

      return reports;
    }
  },

  created() {
    if (this.$route.query.pending) {
      this.show = false;
    }
  },

  methods: {
    goToWorktimeCreate() {
      this.$router.push("/worktime/create");
    },

    addTime(duration, time) {
      let hoursMinutes = time.split(":");
      duration = duration.add({
        hours: parseInt(hoursMinutes[0]),
        minutes: parseInt(hoursMinutes[1])
      });
      return duration;
    },

    getTotalWorktime(time) {
      let hours = 0;

      if (time.days() > 0) {
        hours += time.days() * 24;
        time = time.subtract(time.days(), "days");
      }
      hours += time.hours();
      hours = `${hours}`.padStart(2, "0");
      let minutes = `${time.minutes()}`.padStart(2, "0");
      return `${hours}:${minutes}`;
    }
  }
};
</script>
<style scoped>
.information {
  text-align: center;
  padding: 5px;
  border: 1px solid #333333;
  color: #333333;
  border-radius: 5px;
  margin: 0 auto;
  font-size: 14px;
  max-width: 340px;
  width: 100%;
  display: inline-block;
}
.custom-table thead th {
  border-bottom: 1px solid #333333 !important;
}
.custom-table tbody td {
  border: none !important;
}
.custom-table tbody tr:hover {
  background: transparent !important;
}
.custom-table thead th:nth-child(2),
.custom-table tbody td:nth-child(2) {
  background: #fffbef;
}
.custom-table thead th:nth-child(3),
.custom-table tbody td:nth-child(3) {
  background: #f2f2f2;
}
.custom-table thead th:nth-child(4),
.custom-table tbody td:nth-child(4) {
  background: #f8f9ff;
}
.addition_link button {
  background: #f2c94c;
  border: 1px solid #f2c94c;
}
.addition_link button a {
  text-decoration: none;
  color: #000;
}
</style>
