<template>
  <div class="mx-auto" v-if="!loading">
    <WorktimeListHeader :loading="loading" @filter="getData" />
    <WorktimeListSubHeader :loading="loading" :filter="filter" />
    <WorktimeListContent :loading="loading" />
  </div>
</template>

<script>
import WorktimeListHeader from "@/components/layout/worktime/list/WorktimeListHeader";
import WorktimeListSubHeader from "@/components/layout/worktime/list/WorktimeListSubHeader";
import WorktimeListContent from "@/components/layout/worktime/list/WorktimeListContent";
export default {
  name: "WorktimeList",
  components: {
    WorktimeListHeader,
    WorktimeListSubHeader,
    WorktimeListContent,
  },
  created() {
    this.loading = false;
  },
  data() {
    return {
      loading: false,
      filter: null,
    };
  },
  methods: {
    getData(filter) {
      this.filter = filter;
    },
  },
};
</script>

<style scoped></style>
